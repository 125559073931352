.blog-mobile-menu {
	position: absolute;
	left: 0;
	top: 66px;
	z-index: 99;
	max-height: 0px;
	width: 100%;
	padding: 0 32px;
	visibility: hidden;
	background-color: $white;
	box-shadow: 0 2px 8px 0 rgba(38, 38, 48, 0.27);
	transition: opacity 0.2s, max-height 0.3s 0.1s, visibility 0.3s;
	opacity: 0;

	&--active {
		max-height: unset;
		visibility: visible;
		opacity: 1;
		transition: opacity 0.5s, max-height 0.3s, visibility 0.1s;
	}

	&__item {
		padding: 16px 0;
		border-top: 1px solid $n-90;

		&:first-of-type {
			border-top: none;
		}

		a {
			display: block;
			@include font-base-16($n-17, 700);
		}
	}

	@include phone-landscape-iphone6 {
		top: 114px;
	}
}
