.blog-page-tags {
	&__top-line {
		margin-bottom: 12px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__list {
		display: flex;
		flex-wrap: wrap;
		gap: 8px;
	}

	&__title {
		position: relative;
		padding-left: 32px;
		@include font-base-22($n-17, 700);

		img {
			position: absolute;
			top: 2px;
			left: 0;
			width: 24px;
			height: 24px;
		}
	}

	&__link {
		@include font-base-16($p-48, 700);
		text-decoration: underline;

		&:hover {
			color: $n-17;
		}
	}

	@include tablet-portrait {
		width: 340px;
	}
}

&-tag a {
	padding: 2px 8px;
	display: block;
	overflow: hidden;
	border-radius: 4px;
	background-color: $t-95;
	user-select: none;
	transition: background-color 0.3s ease;
	@include font-base-14($n-17);

	&:hover {
		background-color: $t-80;
	}
}
