.blog-page__subscribe {
	position: relative;
	width: 100%;
	padding: 16px;
	border: solid 2px transparent;
	border-radius: 8px;
	background-color: $white;
	background-clip: padding-box;

	&:before {
		content: "";
		z-index: -1;
		margin: -2px;
		border-radius: inherit;
		background: linear-gradient(to right, $p-48 0%, #29deee 49%, $g-55 100%);
		@include full-size;
	}

	&-content {
		position: relative;
		z-index: 9;

		.form-field {
			margin-bottom: 8px;
		}

		.form-field__error-field {
			margin-top: 0;
		}

		.button {
			width: 100%;
		}
	}

	&-bg-image {
		position: absolute;
		height: 100%;
		width: 100%;
		top: 0px;
		left: 0;
		bottom: 0;
		right: 0;
		border-radius: inherit;
		object-fit: cover;
	}

	&-title {
		margin-bottom: 12px;
		@include font-base-22($n-17, 700);
	}

	@include tablet-portrait {
		padding: 16px;
		height: 172px;
		width: 336px;
	}
}
