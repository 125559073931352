.blog-page-article {
	&-list {
		display: flex;
		flex-direction: column;
		align-items: center;

		&__page {
			padding-top: 32px;
			width: 100%;
			display: grid;
			grid-template-columns: repeat(3, minmax(0, 1fr));
			column-gap: 32px;
			row-gap: 24px;

			&--first-page {
				grid-template-columns: repeat(6, 1fr);

				.blog-page-article-item {
					img {
						cursor: pointer;
					}

					&:nth-of-type(1) {
						grid-column: 1 / 4 !important;

						img {
							height: 212px;

							@include tablet {
								height: 172px;
							}
						}
					}

					&:nth-of-type(2) {
						grid-column: 4 / 7 !important;

						img {
							height: 212px;

							@include tablet {
								height: 172px;
							}
						}
					}

					&:nth-of-type(3n) {
						grid-column: 1 / 3;
					}

					&:nth-of-type(3n + 1) {
						grid-column: 3 / 5;
					}

					&:nth-of-type(3n + 2) {
						grid-column: 5 / 7;
					}
				}

				@include tablet-portrait {
					grid-template-columns: repeat(2, 1fr);
				}
			}

			@include tablet {
				column-gap: 24px;
			}

			@include tablet-portrait {
				grid-template-columns: repeat(2, minmax(0, 1fr));
				column-gap: 24px;
			}

			@include phone {
				grid-template-columns: repeat(2, minmax(0, 1fr));
				column-gap: 24px;
			}

			@include phone-portrait {
				grid-template-columns: 1fr;
			}
		}

		&__pagination {
			margin-top: 48px;

			.pagination__item {
				width: 30px;
				height: 32px;
			}
		}
	}

	&-item {
		display: flex;
		flex-direction: column;

		&__image {
			border-radius: 8px;
			box-shadow:
				0 0 1px 0 rgba(45, 45, 57, 0.5),
				0 8px 12px -4px rgba(45, 45, 57, 0.3);
			overflow: hidden;

			&:hover ~ .blog-page-article-item__title {
				text-decoration: underline;
			}

			img {
				width: 100%;
				display: block;
				object-fit: cover;
				cursor: pointer;

				@include tablet {
					height: 110px;
				}

				@include tablet-portrait {
					height: 192px;
				}

				@include phone {
					height: 156px;
				}

				@include phone-portrait {
					height: 176px;
				}
			}
		}

		&__date {
			margin-top: 16px;
			@include font-base-14($n-35);
		}

		&__title {
			margin-top: 4px;
			@include font-base-22($n-17, 700);
			cursor: pointer;

			&:hover {
				text-decoration: underline;
			}
		}

		&__content {
			margin-top: 4px;
			max-height: 48px;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow-y: hidden;
			@include font-base-16($n-17);
		}

		&__link {
			margin-top: 4px;
			display: flex;
			flex-wrap: wrap;
			gap: 8px;

			a {
				@include font-base-14($p-48, 700);
				text-transform: capitalize;

				&:hover {
					text-decoration: underline;
				}
			}

			span {
				@include font-base-14($p-48, 700);
				user-select: none;
				text-transform: capitalize;
			}
		}
	}
}
