.blog-content {
	position: relative;

	&__search-title {
		@include font-base-22($n-17, 700);
		word-wrap: break-word;
	}

	&__bg {
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		max-height: 1440px;
		height: 100%;
		width: 100%;
	}

	&__wrapper {
		padding-top: 32px;
		padding-bottom: 48px;
		display: flex;
		justify-content: space-between;
		column-gap: 24px;

		.loader {
			margin: auto;
			width: unset;
			height: unset;
		}

		@include tablet {
			justify-content: center;
		}

		@include tablet-portrait {
			flex-direction: column;
			row-gap: 48px;

			.loader {
				margin: 32px auto;
				width: unset;
				height: unset;
			}
		}

		@include phone {
			align-items: 32px center;
			flex-direction: column;
			row-gap: 48px;

			.loader {
				margin: 32px auto;
				width: unset;
				height: unset;
			}
		}
	}

	&__left {
		display: flex;
		flex-direction: column;
		width: 784px;

		@include tablet {
			width: 632px;
		}

		@include tablet-portrait {
			width: 704px;
		}

		@include phone {
			width: 100%;
		}

		.tab-navigation__tab {
			@include font-base-16($n-17, 700);

			&--active {
				@include font-base-16($p-48, 700);
			}
		}

		.tab-navigation__content {
			display: none;
		}
	}

	&__right {
		width: 376px;
		display: flex;
		flex-direction: column;
		row-gap: 32px;
		flex-shrink: 0;

		@include tablet {
			width: 304px;
			row-gap: 24px;
		}

		@include tablet-portrait {
			width: 704px;
		}

		@include phone {
			width: 100%;
			row-gap: 24px;
		}

		&--bottom-line {
			display: flex;
			flex-direction: column;
			row-gap: 32px;
			justify-content: space-between;

			@include tablet {
				row-gap: 24px;
			}

			@include tablet-portrait {
				flex-direction: row;
			}

			@include phone {
				row-gap: 24px;
			}
		}
	}
}
