.blog-page__must-read {
	&-title {
		position: relative;
		padding-left: 32px;
		@include font-base-22($n-17, 700);

		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 24px;
			height: 24px;
		}
	}

	&-list {
		display: flex;
		flex-direction: column;
		list-style: none;
	}

	&-item {
		border-top: 1px solid rgba(38, 38, 48, 0.2);

		a {
			padding: 12px 0;
			display: block;
			@include font-base-16($n-17);

			&:hover {
				text-decoration: underline;
			}
		}

		&:first-of-type {
			border-top: none;
		}
	}
}
