.blog-page {
	overflow-y: hidden;

	&__bg-image {
		position: absolute;
		top: 0px;
		left: 0px;
		z-index: -1;
		width: 300%;
		object-fit: contain;
		object-position: -600px -300px;

		@include tablet-portrait {
			object-position: -1000px 0px;
		}

		@include phone {
			object-position: -800px 0px;
		}

		@include phone-portrait {
			object-position: -500px 0px;
		}
	}

	&-container {
		position: relative;
		margin: 0 auto;
		padding: 0 124px;
		max-width: 1440px;
		height: 100%;
		min-height: inherit;

		@include tablet {
			padding: 0 32px;
			max-width: 100%;
		}

		@include tablet-portrait {
			padding: 0 32px;
			max-width: 768px;
		}

		@include phone {
			padding: 0 46px;
			min-width: 320px;
			max-width: 100%;
		}
	}

	@import "./articleList/ArticleList";

	@import "./blogContent/BlogContent";
	@import "./mobileMenu/MobileMenu";
	@import "./mustRead/MustRead";
	@import "./nav/Nav";
	@import "./subscribe/Subscribe";
	@import "./tags/Tags";
}
