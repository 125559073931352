.blog-page-nav {
	position: sticky;
	top: 0;
	z-index: 999;
	padding: 16px 0px;
	border-width: 0;
	border-bottom-width: 2px;
	border-style: solid;
	border-image: linear-gradient(to right, $p-48 1%, #29deee 49%, $g-55 100%) 1;
	background-color: rgba($color: $white, $alpha: 0.5);
	backdrop-filter: blur(8px);
	transform: translateY(0);
	transition: transform 0.4s 0.2s ease;

	@include phone {
		margin-top: 10px;
	}

	&__divider--mobile {
		display: none;

		@include tablet-portrait {
			display: block;
		}

		@include phone {
			display: block;
		}
	}

	&__container {
		position: relative;
		height: 100%;
		min-height: inherit;
		padding: 0 124px;
		max-width: 1440px;
		margin: 0 auto;

		@include tablet {
			max-width: 100%;
			padding-right: 32px;
			padding-left: 32px;
		}

		@include tablet-portrait {
			max-width: 100%;
			padding: 0 32px;
		}

		@include phone {
			padding: 0 32px;
		}

		@include phone-landscape-iphone6 {
			padding: 0 32px;
		}
	}

	&__desktop {
		display: flex;
		align-items: center;

		&--left {
			display: flex;
			align-items: center;
		}

		&--right {
			margin-left: 24px;

			@include phone {
				margin-left: 0;
			}

			@include phone-landscape-iphone6 {
				width: 100%;
			}
		}

		@media (max-width: 1023px) {
			display: none !important;
		}
	}

	&__link {
		a {
			display: block;
			text-transform: capitalize;
			@include font-base-16($n-17, 700);
		}

		&--active {
			text-decoration: underline;
		}

		&:hover {
			text-decoration: underline;
		}
	}

	&__mobile {
		@media (min-width: 1024px) {
			display: none !important;
		}

		&-selector {
			position: relative;
			padding-right: 28px;
			@include font-base-16($n-17, 700);
			cursor: pointer;

			img {
				position: absolute;
				right: 0;
				top: 50%;
				transform: translateY(-50%);
				width: 20px;
				height: 20px;
			}
		}
	}

	&__wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;

		@include phone-landscape-iphone6 {
			flex-direction: column;
			align-items: flex-start;
			row-gap: 16px;
		}
	}

	&__title {
		@include font-base-24($n-17, 700);
	}

	&__search {
		padding-left: 40px;

		&::placeholder {
			@include font-base-14($n-35);
		}

		&-wrapper {
			position: relative;
			width: 248px;

			@include phone-landscape-iphone6 {
				width: 100%;
			}
		}
	}

	&__search-icon {
		position: absolute;
		top: 6px;
		left: 12px;
		cursor: pointer;
		z-index: 9;
	}

	// Overwrites

	.divider {
		height: 32px;
		margin: 0 16px;
	}

	.form-field {
		margin-bottom: 0;

		.form-field__error-field {
			margin-top: 0;
		}

		.form-field__label-wrapper {
			margin-bottom: 0;
		}
	}
}
